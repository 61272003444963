

import { Component, Prop, Vue } from 'nuxt-property-decorator';
import TheLink from '~/components/TheLink.vue';

@Component({
  components: { TheLink },
})
export default class ArticleList extends Vue {
    @Prop({
      required: true,
      type: Array,
    }) readonly articles!: ITopArticle[];

    @Prop({
      required: true,
      type: String,
    }) readonly title!: string;

    getArticleUrl (article: ITopArticle): string {
      const { url, teaserUrl } = article;
      return teaserUrl || url;
    }

    onClickToTop () {
      this.$emit('click-to-top', '/top', this.$route.name);
    }
};
