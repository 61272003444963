
import Vue from 'vue';
import { mapState } from 'vuex';
import { getAccessToken } from '~/utils';
import { AUTH_API_URL } from '~/constants/config';

export default Vue.extend({
  name: 'GoogleOneTapAuth',

  data () {
    return {
      window: null as any,
      iframeSrc: '' as String,
      scriptLoaded: false as Boolean,
      oneTapInited: false as Boolean,
    };
  },

  computed: {
    ...mapState({
      userData: (state: any) => state.user,
      isUserLoading: (state: any) => state.isUserLoading,
      authMethodsRestricted: (state: any) => state.authMethodsRestricted,
    }),

    isIframe (): Boolean {
      const isAuthenticated = this.userData;
      return Boolean(!isAuthenticated && this.iframeSrc.length);
    },

    user (): IUser | null {
      const { user } = this.$store.state as IRootState;

      return user ?? null;
    },
  },

  watch: {
    scriptLoaded () {
      this.initOneTap();
    },
    userData () {
      this.initOneTap();
    },
    authMethodsRestricted () {
      this.initOneTap();
    },
  },

  mounted () {
    // @ts-ignore
    window.onGoogleLibraryLoad = () => {
      this.scriptLoaded = true;
    };

    window.addEventListener('googleOneTapAuth', () => {
      this.$sendYandexMetrika({
        level1: 'Авторизация',
        level5: 'Целенаправленная авторизация GoogleOneTap',
        level6: 'Google',
        level8: 'Авторизация',
      });
    }, false);
  },

  methods: {
    initOneTap () {
      const isScriptShownOrLoading = this.oneTapInited || !this.scriptLoaded;

      if (isScriptShownOrLoading) {
        return;
      }

      const isUserCountryRestrictedOrLoading = this.authMethodsRestricted === null || this.authMethodsRestricted;
      const isUserExistsOrLoading = this.user || this.isUserLoading;

      if (isUserCountryRestrictedOrLoading || isUserExistsOrLoading) {
        return;
      }

      this.oneTapInited = true;

      // @ts-ignore
      window.google?.accounts.id.initialize({
        client_id: '303105891655-00bc2bi141gtbm0l49nerjrrsmor0jqc.apps.googleusercontent.com',
        context: 'signin',
        prompt_parent_id: 'google-one-tap-auth',
        callback: this.handleCredentialResponse,
      });

      // @ts-ignore
      window.google?.accounts.id.prompt();
    },

    handleCredentialResponse (response: any) {
      const params = {
        ...response,
        isOneTap: true,
      };

      const searchParams = Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&');

      this.iframeSrc = `${AUTH_API_URL}/id/auth/google/callback?${searchParams}&x-auth-app=web`;
    },

    iframeIsLoaded (): void {
      const accessToken = getAccessToken();

      const eventAwesome = new CustomEvent('googleOneTapAuth', {
        bubbles: true,
        detail: {
          accessToken,
          accessTokenSocialNetwork: 'google',
        },
      });

      const $iframe = this.$refs.iframe as HTMLElement;
      $iframe.dispatchEvent(eventAwesome);

      this.iframeSrc = '';
    },
  },
});
