
// Компонент создан для стилизации(скрытия) блока div,
// следующего за текущим. Поэтому нет scoped в style,
// в отличии от родителя. Блок div высотой 250px добавляет
// adfox, поэтому нужны такие костыли, чтобы его скрыть
// при открытии окна поиска
import Vue from 'vue';

export default Vue.extend({
  name: 'ContentWrapperForBranding',
});
