
import Vue, { PropType } from 'vue';
import type { Swiper } from 'swiper';
import { TTheme } from '@/components/ArrowMenu/ArrowMenu';
import ArrowMenuItem from '~/components/ArrowMenu/ArrowMenuItem.vue';
import ArrowMenuButton from '~/components/ArrowMenu/ArrowMenuButton.vue';
export default Vue.extend({
  name: 'ArrowMenu',
  components: {
    ArrowMenuItem,
    ArrowMenuButton,
  },

  props: {
    theme: {
      type: String as PropType<TTheme>,
      default: 'light',
    },
  },

  data () {
    return {
      isVisibleArrows: false,
      scrollLeft: 0,
      visibleItem: 0,
      swiper: {} as Swiper,
    };
  },

  computed: {
    isEnd (): boolean {
      return this.swiper.isEnd ?? true;
    },

    isBeginning (): boolean {
      return this.swiper.isBeginning ?? true;
    },

    arrowMenuClass (): Record<string, any> {
      const className = 'arrow-menu-test';
      const theme = this.theme;
      return {
        [`${className}--${theme}`]: theme,
      };
    },

    navigationClass (): Record<string, any> {
      const className = 'arrow-menu-test__navigation';
      return {
        [`${className}--right-visible`]: !this.isEnd,
        [`${className}--left-visible`]: !this.isBeginning,
      };
    },
  },

  async mounted () {
    const { default: Swiper } = await import('swiper');
    const { Navigation } = await import('swiper/modules');

    const carouselWrapp = this.$refs.arrowMenuWrapp as HTMLElement;
    // @ts-ignore
    const rightBtn = this.$refs?.rightBtn?.$el as HTMLElement;
    // @ts-ignore
    const leftBtn = this.$refs?.leftBtn?.$el as HTMLElement;
    // @ts-ignore
    const slide = this.$slots?.default[0]?.elm as HTMLElement;
    const className = slide?.classList[0];
    this.swiper = new Swiper(carouselWrapp, {
      modules: [Navigation],
      loop: false,
      slidesPerView: 'auto',
      resistanceRatio: 0,
      watchOverflow: false,
      centerInsufficientSlides: true,
      slideClass: className ?? 'swiper-slide',
      navigation: {
        nextEl: rightBtn,
        prevEl: leftBtn,
      },

      freeMode: {
        enabled: true,
        sticky: false,
      },
    });
  },
});
