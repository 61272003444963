// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/arrow.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".arrow-button[data-v-31d098ab]{align-items:center;background:none;border:none;cursor:pointer;display:none;height:100%;justify-content:flex-end;padding:16px 8px;width:32px}@media(min-width:1024px){.arrow-button[data-v-31d098ab]{display:flex}}.arrow-button[data-v-31d098ab]:before{content:\"\";height:16px;-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");pointer-events:none;position:absolute;top:50%;transform:translateY(-50%);width:16px}.arrow-button--red[data-v-31d098ab]:before{background-color:#fff}.arrow-button--light[data-v-31d098ab]:before{background-color:#969698}@media(hover:hover){.arrow-button--light[data-v-31d098ab]:hover:before{background-color:#000}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
