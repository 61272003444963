
import Vue, { Component, PropType } from 'vue';
// @ts-ignore
import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';
import { getRelativeLink } from '~/utils';
import { ILink, TTheme } from '~/components/ArrowMenu/ArrowMenu';

type TCategory = {
  backgroundColor: string;
  id: number;
  link: string;
  testPixel: string;
  textColor: string;
  title: string;
}

export default Vue.extend({
  name: 'ArrowMenuItem',
  components: {
    IntersectionObserver,
  },
  props: {
    item: {
      type: Object as PropType<ILink>,
      required: true,
    },

    appearedItems: {
      type: Array as PropType<number[]>,
      default: () => [],
    },

    theme: {
      type: String as PropType<TTheme>,
      default: 'light',
    },

    currentRubric: {
      type: Object as PropType<TCategory>,
      default: null,
    },
  },

  computed: {
    testPixelComponent (): string | Component {
      return this.isAppear ? 'div' : IntersectionObserver;
    },

    isAppear (): boolean {
      return this.appearedItems?.includes(this.item.id);
    },

    isActive (): boolean {
      return this.$route.path === this.getRelativeLink(this.item.link || '');
    },

    isRedTheme (): boolean {
      return this.theme === 'red';
    },

    menuItemClass (): Record<string, any> {
      const className = 'menu-item';
      const theme = this.theme;
      return {
        [`${className}--${theme}`]: theme,
        [`${className}--active`]: this.isActive,
      };
    },

    linkClass (): Record<string, any> {
      const className = 'menu-item__link';
      const theme = this.theme;
      return {
        [`${className}--${theme}`]: theme,
        [`${className}--colored`]: this.item.backgroundColor,
        [`${className}--active`]: this.isActive,
      };
    },

    titleClass (): Record<string, any> {
      const className = 'menu-item__title';
      const theme = this.theme;
      return {
        [`${className}--${theme}`]: theme,
        [`${className}--colored`]: this.item.backgroundColor,
        [`${className}--active`]: this.isActive,
      };
    },

    componentLink (): string | Component {
      return this.isActive ? 'span' : 'a';
    },

    attrsLink (): Record<string, any> {
      return this.isActive ? {} : { href: this.item.link };
    },

    linkColors (): string | undefined | null {
      return `
        --bg: ${this.item.backgroundColor || 'none'};
        --text: ${this.item.textColor || '#5C5C5C'};
      `;
    },
  },

  methods: {
    getRelativeLink,
    onAppearItem () {
      this.$emit('appear-item', this.item.id);
    },
  },
});
